<app-header></app-header>

<!-- Loading spinner -->
<div *ngIf="loading" class="loading-container flex-content-center">
  <div class="spinner-border text-primary" style="margin:auto" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>


<main class="page-wrapper">

  <app-dashboard class="page-title-overlap bg-accent pt-4"></app-dashboard>
  <div class="container mb-5 pb-3">
    <div class="bg-light shadow-lg rounded-3 overflow-hidden">
      <div class="row">
        <app-sidebar class="col-lg-4 pe-xl-5"></app-sidebar>
        <!-- Content-->
        <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
          <router-outlet></router-outlet>
        </section>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
