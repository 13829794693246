import { Component } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Location, NgForOf, NgIf} from "@angular/common";
import {AdminApiService} from "../service/admin-api.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-add-banner',
  standalone: true,
    imports: [
        FormsModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule
    ],
  templateUrl: './add-banner.component.html',
  styleUrl: './add-banner.component.css'
})
export class AddBannerComponent {
  banner: any;
  showErrorMessage: boolean;
  errorMessage: any;
  bannerTypes: any;
  protected operation: string;


  protected image: any;
  protected imageSrc: any;


  constructor(private adminApiService: AdminApiService, private location: Location,private route: ActivatedRoute) {
    this.showErrorMessage = false;
    this.banner = {
      bannerType:null,
      name:null,title:null
    }
    this.operation = 'ADD';

    this.bannerTypes = ['HOMEPAGE_CAROUSEL', 'HOMEPAGE_BANNER', 'CATEGORY', 'TAG', 'CATEGORY_TAG'];
  }

  ngOnInit(): void {

    let name = this.route.snapshot.queryParams["name"];
    let bannerType = this.route.snapshot.queryParams["bannerType"];
    if(name && bannerType) {
      this.operation = "EDIT";
      this.adminApiService.getBanner(name, bannerType).subscribe(banner => {
        this.banner = banner;

        this.imageSrc = environment.siteBaseURL + "/img/banner/" + bannerType.toLowerCase() + "-" + name.toLowerCase() + ".png";


      })
    }
  }



  save(): void {
    this.showErrorMessage = false;


    if (!this.banner.name || !this.banner.bannerType || !this.banner.title) {
      this.showErrorMessage = true;
      this.errorMessage = 'All the input fields are mandatory';
      return;
    }

    if(this.operation == 'ADD') {
      this.adminApiService.getBanner(this.banner.name, this.banner.bannerType).subscribe((response: any) => {
        if (response && response.name) {
          this.showErrorMessage = true;
          this.errorMessage = 'There is already a banner with the same name and type defined: ' + response.name;
          return;
        }
        this.callCreateUpdateAPI();
      }, error => {
        //product not found, all good!
        this.callCreateUpdateAPI();
      })
    } else {
      this.callCreateUpdateAPI();
    }
  }

  private callCreateUpdateAPI() {
    this.adminApiService.createUpdateBanner(this.banner, this.operation)
      .subscribe(dbBanner => {
        console.log(dbBanner);

        if (this.image) {
          this.imagesToUpload++;
          this.adminApiService.uploadBannerImage(dbBanner, this.image, this.onImageUploaded.bind(this));
        }

        if(this.imagesToUpload == 0) {
          //no need to wait for the callback, redirect!
          window.location.assign("/view-banners")
        }

      });
  }

  private imagesToUpload : number = 0;
  private onImageUploaded() {
    this.imagesToUpload--;

    if(this.imagesToUpload == 0) {
      //all uplaoded
      window.location.assign("/view-banners")
    }
  }




  handleFileSelectForImage(evt: any) {
    this.image = evt.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.image);

    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
  }
}
