<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">{{operation == 'ADD'?'Add New':'Edit'}}  Design</h2>
  </div>
  <form>
    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-product-name" >Message</label>
      <input class="form-control" type="text" id="unp-product-name" name="name" [(ngModel)]="productDesign.message">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>
    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-product-tags" >Tags</label>
      <input class="form-control" type="text" id="unp-product-tags" name="tags" [(ngModel)]="productDesign.tags">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>
    <div class="mb-3 py-2">
      <label class="form-label" for="unp-product-description">Product description</label>
      <textarea class="form-control" rows="6" id="unp-product-description" name="description" [(ngModel)]="productDesign.description"></textarea>
      <div class="bg-secondary p-3 fs-ms rounded-bottom"><span class="d-inline-block fw-medium me-2 my-1">Markdown supported:</span><em class="d-inline-block border-end pe-2 me-2 my-1">*Italic*</em><strong class="d-inline-block border-end pe-2 me-2 my-1">**Bold**</strong><span class="d-inline-block border-end pe-2 me-2 my-1">- List item</span><span class="d-inline-block border-end pe-2 me-2 my-1">##Heading##</span><span class="d-inline-block">--- Horizontal rule</span></div>
    </div>

    <div class="row align-items-center">
      <label class="col-form-label" >Styles</label>
      <div class="row" *ngFor="let style of productDesign.styles; index as i">
        <div class="col-6 pb-2">
          <input class="form-control" placeholder="Style name" type="text" id="unp-product-color-{{i}}" name="styleName{{i}}" [(ngModel)]="style.name">
        </div>
        <div class="col-6 pb-2">
          <input class="form-control" type="text" placeholder="Message" id="unp-product-color-{{i}}" name="styleImagePrefixSlug{{i}}" [(ngModel)]="style.imagesPrefixSlug">
        </div>
        <div class="col-6 pb-2">
          <div class="mb-3 pb-2">
            <div class="file-drop-area mb-3" *ngIf="genericImageLight[i] || operation == 'EDIT'">
              <img [src]="genericImageLightSrc[i]" width="200" height="200"/>
              <button *ngIf="operation == 'ADD'" class="btn btn-sm my-3 btn-primary d-block w-100"  (click)="genericImageLight[i] = null">Remove Image</button>
            </div>
            <div class="file-drop-area mb-3" *ngIf="genericImageLight[i] == null">
              <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
              <input class="file-drop-input" type="file"  (change)="handleFileSelect($event, i, 'light')" accept="image/png">
              <div class="form-text">{{style.name}} Image for Light</div>
            </div>
          </div>
        </div>

        <div class="col-6 pb-2">
          <div class="mb-3 pb-2">
            <div class="file-drop-area mb-3" *ngIf="genericImageDark[i] || operation == 'EDIT'">
              <img [src]="genericImageDarkSrc[i]" width="200" height="200"/>
              <button *ngIf="operation == 'ADD'" class="btn btn-sm my-3 btn-primary d-block w-100"  (click)="genericImageDark[i] = null">Remove Image</button>
            </div>
            <div class="file-drop-area mb-3" *ngIf="genericImageDark[i] == null">
              <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
              <input class="file-drop-input" type="file"  (change)="handleFileSelect($event, i, 'dark')" accept="image/png">
              <div class="form-text">{{style.name}} Image for Dark</div>
            </div>
          </div>
        </div>
        <div class="col-3 pb-2">
          <button class="btn btn-secondary" type="button" (click)="this.productDesign.styles.splice(this.productDesign.styles.indexOf(style),1)"><i class="ci-close fs-lg me-2"></i>Remove</button>
        </div>
      </div>
    </div>
    <div class="mb-3 py-2" >
      <button class="btn btn-secondary" type="submit" (click)="addStyle()"><i class="ci-star fs-lg me-2"></i>Add new style</button>
    </div>



    <div class="alert alert-danger" *ngIf="showErrorMessage">
      <strong>Eroare!</strong> {{errorMessage}}.
    </div>

    <button class="btn btn-primary d-block w-100" type="submit" (click)="save()"><i class="ci-cloud-upload fs-lg me-2"></i>Upload Product</button>
  </form>
</div>
