import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {SessionStateService} from "./session-state.service";
import {catchError, Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {



  constructor(private http: HttpClient, private sessionStateService:SessionStateService) { }

  getAdminAPIRawProductGenericBasePresignedURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/raw-product/generic-image-presigned-url';
  }

  getAdminAPIRawProductBasePresignedURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/raw-product/image-presigned-url';
  }

  getAdminAPIProductDesignImageBasePresignedURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/product-design/image-presigned-url';
  }

  getAdminAPIProductImageBasePresignedURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/product/image-presigned-url';
  }

  getAdminAPIBannerImageBasePresignedURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/banner/image-presigned-url';
  }

  getAdminAPIRawProductBaseURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/raw-product';
  }

  getAdminAPIProductDesignBaseURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/product-design';
  }

  getAdminAPIProductBaseURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/product';
  }

  getAdminAPIBannerBaseURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/banner';
  }

  getAdminAPIDictionaryBaseURL() {
    return environment.adminAPIBaseEndpoint + 'admin-function/v1/dictionary';
  }


  getAuthHeaders() {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.sessionStateService.getIdToken(),
    });
    const options = {headers: headers};
    return options;
  }


  //DICTIONARY ITEMS
  getDictionaryItems(dictionaryName: any) {
    return this.http.get<any>(this.getAdminAPIDictionaryBaseURL() + '/' + dictionaryName, this.getAuthHeaders());
  }

  addDictionaryItem(dictionaryName: any, newItemValue: any) {
    return this.http.post<any>(this.getAdminAPIDictionaryBaseURL() + '/' + dictionaryName + "/" + newItemValue,null, this.getAuthHeaders());
  }

  deleteDictionaryItem(dictionaryName: any, value: any) {
    return this.http.delete<any>(this.getAdminAPIDictionaryBaseURL() + '/' + dictionaryName + "/" + value, this.getAuthHeaders());

  }


  //RAW PRODUCT
  uploadRawProductGenericImage(rawProduct: any,genericImage: File, onSavedSuccessful:() => void) {

    this.http.get<any>(this.getAdminAPIRawProductGenericBasePresignedURL() + "/" + rawProduct.shortSKU + "/" + rawProduct.idRP, this.getAuthHeaders())
      .subscribe(response => {
        const headers = new HttpHeaders({
          'Content-Type': 'image/png'
        });
        const options = {headers: headers};

        return this.http.put(response.presignedURL, genericImage, options).subscribe(response =>{onSavedSuccessful()});
      });

  }

  uploadRawProductImage(rawProduct: any,color:any, image: File, number: string, onImageUploaded:() => void) {

    this.http.get<any>(this.getAdminAPIRawProductBasePresignedURL() + "/" + rawProduct.shortSKU + "/" + rawProduct.idRP +
      "/" + color.slug + "/" + number, this.getAuthHeaders())
      .subscribe(response => {
        const headers = new HttpHeaders({
          'Content-Type': 'image/png'
        });
        const options = {headers: headers};

        return this.http.put(response.presignedURL, image, options).subscribe(response => {onImageUploaded()});
      });



  }

  getRawProductsByCategory(category: any) {
    return this.http.get(this.getAdminAPIRawProductBaseURL() + "/filter/" + category , this.getAuthHeaders());
  }

  getRawProduct(shortSKU:any, idRP: any) {
    return this.http.get(this.getAdminAPIRawProductBaseURL() + "/" + shortSKU + "/" + idRP , this.getAuthHeaders());
  }

  createUpdateRawProduct(rawProduct: any, operation: string) {
    if(operation == 'ADD') {
      return this.http.put(this.getAdminAPIRawProductBaseURL(), rawProduct, this.getAuthHeaders());
    } else {
      return this.http.post(this.getAdminAPIRawProductBaseURL(), rawProduct, this.getAuthHeaders());
    }
  }

  deleteRawProduct(rawProduct: any) {
    return this.http.delete(this.getAdminAPIRawProductBaseURL() + "/" + rawProduct.shortSKU + "/" + rawProduct.idRP , this.getAuthHeaders());
  }



  //PRODUCT DESIGN
  uploadProductDesignImage(productDesign: any,style:any, image: File, type: string, onImageUploaded:() => void) {

      this.http.get<any>(this.getAdminAPIProductDesignImageBasePresignedURL() + "/" + productDesign.mainTagInitials + "/" + productDesign.idPD +
        "/" + style.imagesPrefixSlug + "/" + type, this.getAuthHeaders())
        .subscribe(response => {
          const headers = new HttpHeaders({
            'Content-Type': 'image/png'
          });
          const options = {headers: headers};

          return this.http.put(response.presignedURL, image, options).subscribe(response => {onImageUploaded()});
        });



  }

  getProductDesignsByTag(tag: any) {
    return this.http.get(this.getAdminAPIProductDesignBaseURL() + "/filter/" + tag , this.getAuthHeaders());
  }

  getProductDesign(mainTagInitials:any, idPD: any) {
    return this.http.get(this.getAdminAPIProductDesignBaseURL() + "/" + mainTagInitials + "/" + idPD , this.getAuthHeaders());
  }

  createUpdateProductDesign(productDesign: any, operation: string) {
    if(operation == 'ADD') {
      return this.http.put(this.getAdminAPIProductDesignBaseURL(), productDesign, this.getAuthHeaders());
    } else {
      return this.http.post(this.getAdminAPIProductDesignBaseURL(), productDesign, this.getAuthHeaders());
    }
  }

  deleteProductDesign(productDesign: any) {
    return this.http.delete(this.getAdminAPIProductDesignBaseURL() + "/" + productDesign.mainTagInitials + "/" + productDesign.idPD , this.getAuthHeaders());
  }


  //PRODUCT
  uploadProductImage(product: any,style:any,color:any, order:number, image: File, onImageUploaded:() => void) {

    this.http.get<any>(this.getAdminAPIProductImageBasePresignedURL() + "/" + product.sku + "/" + style.imagesPrefixSlug +
      "/" + color.slug + "/" + order, this.getAuthHeaders())
      .subscribe(response => {
        const headers = new HttpHeaders({
          'Content-Type': 'image/png'
        });
        const options = {headers: headers};

        return this.http.put(response.presignedURL, image, options).subscribe(response => {onImageUploaded()});
      });



  }

  getProductsByTag(tag: any) {
    return this.http.get(this.getAdminAPIProductBaseURL() + "/filter-by-tag/" + tag , this.getAuthHeaders());
  }

  getProductsByCategory(category: any) {
    return this.http.get(this.getAdminAPIProductBaseURL() + "/filter-by-category/" + category , this.getAuthHeaders());
  }

  getProduct(sku:any) {
    return this.http.get(this.getAdminAPIProductBaseURL() + "/" + sku , this.getAuthHeaders());
  }

  createUpdateProduct(product: any, operation: string) {
    if(operation == 'ADD') {
      return this.http.put(this.getAdminAPIProductBaseURL(), product, this.getAuthHeaders());
    } else {
      return this.http.post(this.getAdminAPIProductBaseURL(), product, this.getAuthHeaders());
    }
  }

  deleteProduct(product: any) {
    return this.http.delete(this.getAdminAPIProductBaseURL() + "/" + product.sku  , this.getAuthHeaders());
  }

  uploadMainProductImage(product: any, mainImage: any, onImageUploaded: OmitThisParameter<() => void>) {
    this.http.get<any>(this.getAdminAPIProductImageBasePresignedURL() + "/" + product.sku + "/-/-/0", this.getAuthHeaders())
      .subscribe(response => {
        const headers = new HttpHeaders({
          'Content-Type': 'image/png'
        });
        const options = {headers: headers};

        return this.http.put(response.presignedURL, mainImage, options).subscribe(response => {onImageUploaded()});
      });
  }


  //BANNER
  uploadBannerImage(banner: any,image: File, onImageUploaded:() => void) {

    this.http.get<any>(this.getAdminAPIBannerImageBasePresignedURL() + "/" + banner.name + "/" + banner.bannerType, this.getAuthHeaders())
      .subscribe(response => {
        const headers = new HttpHeaders({
          'Content-Type': 'image/png'
        });
        const options = {headers: headers};

        return this.http.put(response.presignedURL, image, options).subscribe(response => {onImageUploaded()});
      });



  }

  getBanners() {
    return this.http.get(this.getAdminAPIBannerBaseURL() + "/all"  , this.getAuthHeaders());
  }


  getBanner(name:any, bannerType: any) {
    return this.http.get(this.getAdminAPIBannerBaseURL() + "/" + name + "/" + bannerType , this.getAuthHeaders());
  }

  createUpdateBanner(banner: any, operation: string) {
    if(operation == 'ADD') {
      return this.http.put(this.getAdminAPIBannerBaseURL(), banner, this.getAuthHeaders());
    } else {
      return this.http.post(this.getAdminAPIBannerBaseURL(), banner, this.getAuthHeaders());
    }
  }

  deleteBanner(banner: any) {
    return this.http.delete(this.getAdminAPIBannerBaseURL() + "/" + banner.name + "/" + banner.bannerType  , this.getAuthHeaders());
  }

}
