<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center border-bottom">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">Your Raw Products<span class="badge bg-faded-accent fs-sm text-body align-middle ms-2">{{rawProducts && rawProducts.length}}</span></h2>
    <div class="py-2">
      <div class="d-flex flex-nowrap align-items-center pb-3">
        <label class="form-label fw-normal text-nowrap mb-0 me-2" for="unp-category">Filter by category:</label>
        <select name="dictionaryName" class="form-select me-2" id="unp-category" (change)="onCategoryChange()" [(ngModel)]="category" required>
          <option *ngFor="let category of categories" value="{{category}}">{{category}}</option>
        </select>
      </div>
    </div>
    <div class="py-2">
      <div class="d-flex flex-nowrap align-items-center pb-3">
        <a class="btn btn-primary d-block w-100" [routerLink]="['/add-raw-product']"><i class="ci-cloud-upload fs-lg me-2"></i>Add Raw Product</a>
      </div>
    </div>
  </div>
  <!-- Product-->
  <div class="d-block d-sm-flex align-items-center py-4 border-bottom" *ngFor="let rawProduct of rawProducts" ><a class="d-block mb-3 mb-sm-0 me-sm-4 ms-sm-0 mx-auto" href="marketplace-single.html" style="width: 12.5rem;"><img class="rounded-3" src="{{environment.siteBaseURL}}/img/raw-product/{{rawProduct.shortSKU | lowercase }}{{rawProduct.idRP}}.png" alt="Product"></a>
    <div class="text-center text-sm-start">
      <h3 class="h6 product-title mb-2"><a href="marketplace-single.html">{{rawProduct.name}}</a></h3>
      <!--
      <div class="d-inline-block text-accent">$18.<small>00</small></div>
      <div class="d-inline-block text-muted fs-ms border-start ms-2 ps-2">Sales: <span class="fw-medium">26</span></div>
      <div class="d-inline-block text-muted fs-ms border-start ms-2 ps-2">Earnings: <span class="fw-medium">$327.<small>60</small></span></div>
      -->
      <div class="d-flex justify-content-center justify-content-sm-start pt-3">
        <!--
        <button class="btn bg-faded-accent btn-icon me-2" type="button" data-bs-toggle="tooltip" title="Download"><i class="ci-download text-accent"></i></button>aa
        -->
        <button class="btn bg-faded-info btn-icon me-2" type="button" data-bs-toggle="tooltip" title="Edit" [routerLink]="['/add-raw-product']" [queryParams]="{shortSKU: rawProduct.shortSKU, idRP: rawProduct.idRP}"><i class="ci-edit text-info"></i></button>
        <button class="btn bg-faded-danger btn-icon" type="button" data-bs-toggle="tooltip" title="Delete" (click)="deleteRawProduct(rawProduct)"><i class="ci-trash text-danger"></i></button>
      </div>
    </div>
  </div>

</div>
