import { Component } from '@angular/core';
import {HeaderComponent} from '../common/header/header.component';
import {FooterComponent} from '../common/footer/footer.component';
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {DashboardComponent} from "../common/dashboard/dashboard.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarComponent, DashboardComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {

}
