<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">{{operation == 'ADD'?'Add New':'Edit'}}  Banner</h2>

  </div>
  <form>

    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-banner-name" >Name</label>
      <input class="form-control" type="text" id="unp-banner-name" name="name" [(ngModel)]="banner.name">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>

    <div class="mb-3 pb-2 row">
      <div class="py-2 col-4">
        <label for="unp-bannerType" class="form-label">Choose banner type</label>
        <select name="category" class="form-select me-2" id="unp-bannerType" [(ngModel)]="banner.bannerType" required>
          <option *ngFor="let bannerType of bannerTypes" value="{{bannerType}}">{{bannerType}}</option>
        </select>
      </div>

    </div>




    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-banner-title" >Title</label>
      <input class="form-control" type="text" id="unp-banner-title" name="title" [(ngModel)]="banner.title">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>

    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-banner-subtitle" >SubTitle</label>
      <input class="form-control" type="text" id="unp-banner-subtitle" name="subTitle" [(ngModel)]="banner.subTitle">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>

    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-banner-pretitle" >PreTitle</label>
      <input class="form-control" type="text" id="unp-banner-pretitle" name="preTitle" [(ngModel)]="banner.preTitle">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>

    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-banner-button-label" >Button label</label>
      <input class="form-control" type="text" id="unp-banner-button-label" name="actionButtonName" [(ngModel)]="banner.actionButtonName">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>

    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-banner-button-url" >Button url</label>
      <input class="form-control" type="text" id="unp-banner-button-url" name="actionButtonURL" [(ngModel)]="banner.actionButtonURL">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>

    <div class="mb-3 pb-2">
      <label class="col-form-label" >Image</label>
      <div class="file-drop-area mb-3" *ngIf="image || operation == 'EDIT'">
        <img [src]="imageSrc" width="200" height="200"/>
        <button *ngIf="operation == 'ADD'" class="btn btn-sm my-3 btn-primary d-block w-100"  (click)="image = null">Remove Image</button>
      </div>
      <div class="file-drop-area mb-3" *ngIf="image == null">
        <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
        <input class="file-drop-input" type="file"  (change)="handleFileSelectForImage($event)" accept="image/png">
        <div class="form-text">Main Product Image</div>
      </div>
    </div>



    <div class="alert alert-danger" *ngIf="showErrorMessage">
      <strong>Eroare!</strong> {{errorMessage}}.
    </div>


    <button class="btn btn-primary d-block w-100" type="submit" (click)="save()"><i class="ci-cloud-upload fs-lg me-2"></i>Upload Banner</button>

  </form>
</div>
