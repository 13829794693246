import { Component } from '@angular/core';
import {FormsModule} from "@angular/forms";
import {LowerCasePipe, NgForOf} from "@angular/common";
import {AdminApiService} from "../service/admin-api.service";
import {RouterModule} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-view-product-designs',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    RouterModule,
    LowerCasePipe
  ],
  templateUrl: './view-product-designs.component.html',
  styleUrl: './view-product-designs.component.css'
})
export class ViewProductDesignsComponent {
  filterTag: any;

  protected productDesigns: any;

  constructor(private adminApiService: AdminApiService) {
  }

  onTagSubmit() {
    if(this.filterTag === undefined || this.filterTag === '') {
      return;
    }
    this.adminApiService.getProductDesignsByTag(this.filterTag).subscribe(response =>
      this.productDesigns = response);
  }

  deleteProductDesign(productDesign: any) {
    if(confirm("Are you sure you want to delete product design " + productDesign.message)) {
      this.adminApiService.deleteProductDesign(productDesign).subscribe(response=>{
        window.location.assign("/view-product-designs");
      })

    }
  }

  protected readonly environment = environment;
}
