<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center border-bottom">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">Your Banners<span class="badge bg-faded-accent fs-sm text-body align-middle ms-2">{{banners && banners.length}}</span></h2>


    <div class="py-2 col-2">
      <div class="d-flex flex-nowrap align-items-center pb-3">
        <a class="btn btn-primary d-block w-100" [routerLink]="['/add-banner']"><i class="ci-cloud-upload fs-lg me-2"></i></a>
      </div>
    </div>
  </div>
  <!-- Product-->
  <div class="d-block d-sm-flex align-items-center py-4 border-bottom" *ngFor="let banner of banners" >
    <div class="d-block mb-3 mb-sm-0 me-sm-4 ms-sm-0 mx-auto w-25">
      <img class="rounded-3"  src="{{environment.siteBaseURL}}/img/banner/{{banner.bannerType | lowercase}}-{{banner.name | lowercase}}.png" alt="Product">
  </div>
    <div class="text-center text-sm-start">
      <h3 class="h6 product-title mb-2">{{banner.name}}</h3>
      <div class="d-inline-block text-accent">{{banner.bannerType}}</div>
      <div class="d-inline-block text-muted fs-ms border-start ms-2 ps-2">Titlu: <span class="fw-medium">{{banner.title}}</span></div>
      <!--
      <div class="d-inline-block text-muted fs-ms border-start ms-2 ps-2">Earnings: <span class="fw-medium">$327.<small>60</small></span></div>
      -->
      <div class="d-flex justify-content-center justify-content-sm-start pt-3">
        <!--
        <button class="btn bg-faded-accent btn-icon me-2" type="button" data-bs-toggle="tooltip" title="Download"><i class="ci-download text-accent"></i></button>aa
        -->
        <button class="btn bg-faded-info btn-icon me-2" type="button" data-bs-toggle="tooltip" title="Edit" [routerLink]="['/add-banner']" [queryParams]="{name: banner.name, bannerType: banner.bannerType}"><i class="ci-edit text-info"></i></button>
        <button class="btn bg-faded-danger btn-icon me-2" type="button" data-bs-toggle="tooltip" title="Delete" (click)="deleteBanner(banner)"><i class="ci-trash text-danger"></i></button>
      </div>
    </div>
  </div>

</div>
