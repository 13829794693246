<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">{{operation == 'ADD'?'Add New':'Edit'}}  Product</h2>
    <div class="py-2">
      <label for="unp-category" class="form-label fw-normal text-nowrap mb-0 me-2">Choose category:</label>
      <select name="category" class="form-select me-2" id="unp-category" [(ngModel)]="rawProduct.category" required>
        <option *ngFor="let category of categories" value="{{category}}">{{category}}</option>
      </select>
    </div>
  </div>
  <form>
    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-product-name" >Product name</label>
      <input class="form-control" type="text" id="unp-product-name" name="name" [(ngModel)]="rawProduct.name">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>
    <div class="mb-3 py-2">
      <label class="form-label" for="unp-product-description">Product description</label>
      <textarea class="form-control" rows="6" id="unp-product-description" name="description" [(ngModel)]="rawProduct.description"></textarea>
      <div class="bg-secondary p-3 fs-ms rounded-bottom"><span class="d-inline-block fw-medium me-2 my-1">Markdown supported:</span><em class="d-inline-block border-end pe-2 me-2 my-1">*Italic*</em><strong class="d-inline-block border-end pe-2 me-2 my-1">**Bold**</strong><span class="d-inline-block border-end pe-2 me-2 my-1">- List item</span><span class="d-inline-block border-end pe-2 me-2 my-1">##Heading##</span><span class="d-inline-block">--- Horizontal rule</span></div>
    </div>


    <div class="row align-items-center">
      <label class="col-form-label" >Design Boundaries for image 1</label>
      <div class="row">
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries1x" [(ngModel)]="rawProduct.designBoundaries1.x" placeholder="x">
        </div>
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries1y" [(ngModel)]="rawProduct.designBoundaries1.y" placeholder="y">
        </div>
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries1width" [(ngModel)]="rawProduct.designBoundaries1.width" placeholder="width">
        </div>
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries1heigh" [(ngModel)]="rawProduct.designBoundaries1.height" placeholder="height">
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <label class="col-form-label" >Design Boundaries for image 2</label>
      <div class="row">
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries2x" [(ngModel)]="rawProduct.designBoundaries2.x" placeholder="x">
        </div>
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries2y" [(ngModel)]="rawProduct.designBoundaries2.y" placeholder="y">
        </div>
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries2width" [(ngModel)]="rawProduct.designBoundaries2.width" placeholder="width">
        </div>
        <div class="col-4 pb-2">
          <input class="form-control" type="number"  name="designBoundaries2height" [(ngModel)]="rawProduct.designBoundaries2.height" placeholder="height">
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <label class="col-form-label" >Colors</label>
      <div class="row" *ngFor="let color of rawProduct.colors; index as i">
        <div class="col-4 pb-2">
          <input class="form-control" type="text" id="unp-product-color-name-{{i}}" name="colorName{{i}}" [(ngModel)]="color.name">
        </div>
        <div class="col-4 pb-2">
          <select class="form-select me-2" id="unp-product-color-type-{{i}}" name="colorType{{i}}" [(ngModel)]="color.type">
            <option  value="light">Light</option>
            <option  value="dark">Dark</option>
          </select>
        </div>
        <div class="col-4 pb-2">
          <input class=" form-control-color" type="color" id="color-input1{{i}}" name="colorColor1{{i}}" [(ngModel)]="color.color1">
          <input class=" form-control-color" type="color" id="color-input2{{i}}" name="colorColor2{{i}}" [(ngModel)]="color.color2">
          <input class=" form-control-color" type="color" id="color-input3{{i}}" name="colorColor3{{i}}" [(ngModel)]="color.color3">
        </div>
        <div class="col-6 pb-2">
          <div class="mb-3 pb-2">
            <div class="file-drop-area mb-3" *ngIf="image1[i] || operation == 'EDIT'">
              <img [src]="image1Src[i]" width="200" height="200"/>
              <button *ngIf="operation == 'ADD'" class="btn btn-sm my-3 btn-primary d-block w-100"  (click)="image1[i] = null">Remove Image</button>
            </div>
            <div class="file-drop-area mb-3" *ngIf="image1[i] == null">
              <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
              <input class="file-drop-input" type="file"  (change)="handleFileSelect($event, i, '1')" accept="image/png">
              <div class="form-text"> Image 1 for {{color.name}}</div>
            </div>
          </div>
        </div>

        <div class="col-6 pb-2">
          <div class="mb-3 pb-2">
            <div class="file-drop-area mb-3" *ngIf="image2[i] || operation == 'EDIT'">
              <img [src]="image2Src[i]" width="200" height="200"/>
              <button *ngIf="operation == 'ADD'" class="btn btn-sm my-3 btn-primary d-block w-100"  (click)="image2[i] = null">Remove Image</button>
            </div>
            <div class="file-drop-area mb-3" *ngIf="image2[i] == null">
              <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
              <input class="file-drop-input" type="file"  (change)="handleFileSelect($event, i, '2')" accept="image/png">
              <div class="form-text">Image 2 for {{color.name}}</div>
            </div>
          </div>
        </div>
        <div class="col-3 pb-2">
          <button class="btn btn-secondary" type="button" (click)="this.rawProduct.colors.splice(this.rawProduct.colors.indexOf(color),1)"><i class="ci-close fs-lg me-2"></i>Remove</button>
        </div>
      </div>
    </div>
    <div class="mb-3 py-2" >
      <button class="btn btn-secondary" type="submit" (click)="addColor()"><i class="ci-star fs-lg me-2"></i>Add new color</button>
    </div>

    <div class="col-md-3 mb-3">
      <label class="form-label" for="wearby">Wear By</label>
      <select class="form-select" required id="wearby" name="wearby" [(ngModel)]="rawProduct.wearBy">
        <option value="">Choose wear by...</option>
        <option value="MALE">Male</option>
        <option value="FEMALE">Female</option>
        <option value="UNISEX">Unisex</option>
      </select>
    </div>


    <div class="row">
      <div class="mb-3 pb-2">
        <div class="file-drop-area mb-3" *ngIf="genericImage || operation == 'EDIT'">
          <img [src]="genericImageSrc" width="200" height="200"/>
          <button *ngIf="operation == 'ADD'" class="btn btn-sm my-3 btn-primary d-block w-100"  (click)="genericImage = null">Remove Image</button>
        </div>
        <div class="file-drop-area mb-3" *ngIf="genericImage == null">
          <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
          <input class="file-drop-input" type="file"  (change)="handleGenericFileSelect($event)" accept="image/png">
          <div class="form-text">Generic Raw Product Image</div>
        </div>
      </div>
    </div>




    <div class="alert alert-danger" *ngIf="showErrorMessage">
      <strong>Eroare!</strong> {{errorMessage}}.
    </div>

    <button class="btn btn-primary d-block w-100" type="submit" (click)="save()"><i class="ci-cloud-upload fs-lg me-2"></i>Upload Product</button>
  </form>
</div>
