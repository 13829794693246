import {Component, ErrorHandler} from '@angular/core';
import {SessionStateService} from "../../service/session-state.service";
import { FormsModule } from '@angular/forms';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {catchError} from "rxjs";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-sign-in-modal',
  standalone: true,
    imports: [FormsModule, NgClass],
  templateUrl: './sign-in-modal.component.html',
  styleUrl: './sign-in-modal.component.css'
})
export class SignInModalComponent {

    activeTab: any;

    email: any;
    password: any;
    newPassword: any;

    signInResponse: any;

  constructor(private sessionStateService: SessionStateService,private modalService: NgbModal) {
      this.activeTab = 'signIn';
  }

  signIn() {
    this.sessionStateService.signIn(this.email, this.password)
        .subscribe(response => {
            this.signInResponse = response;
          if(response.ChallengeName && response.ChallengeName === "NEW_PASSWORD_REQUIRED") {
            this.activeTab = 'newPassword';
          } else {
              this.sessionStateService.storeSignInData(response.AuthenticationResult);
              this.modalService.dismissAll();
          }
        });

  }


    setNewPassword() {
        return this.sessionStateService.newPassword(this.email, this.password, this.signInResponse).subscribe(response => {
            this.sessionStateService.storeSignInData(response);
        });
    }
}
