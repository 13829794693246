<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">Manage Dictionaries</h2>
    <div class="py-2">
      <select name="dictionaryName" class="form-select me-2" id="unp-category" (change)="onDictionaryNameChange()" [(ngModel)]="dictionaryName" required>
        <option>categories</option>
        <option>keys</option>
        <option>featured-homepage-products</option>
        <option>popular-tags</option>
      </select>
    </div>
  </div>

  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2" *ngIf="dictionaryName !== undefined">
  <!-- Basic list group -->
  <ul class="list-group" >
    <li class="list-group-item active">{{dictionaryName}} items: </li>
    <li class="list-group-item" *ngFor="let value of dictionaryItems">
      <button class="btn btn-secondary" (click)="deleteItem(value)"><i class="ci-close"></i></button>
      {{value}}
    </li>
  </ul>
  </div>

  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">

    <!-- Forms validation: status text -->
    <form class="needs-validation" novalidate *ngIf="dictionaryName !== undefined">
      <div class="row">
        <div class="col-md-6 mb-3">
          <input class="form-control" type="text" id="validationCustom01" placeholder="Value" name="value" [(ngModel)]="newItemValue" required>
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-6 mb-3">
          <button class="btn btn-primary" type="submit" (click)="addNewValue()">Add Value</button>
        </div>
      </div>
    </form>
  </div>
</div>
