import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Location, NgForOf, NgIf} from "@angular/common";
import {AdminApiService} from "../service/admin-api.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-add-product-design',
  standalone: true,
    imports: [
        FormsModule,
        NgForOf,
        NgIf
    ],
  templateUrl: './add-product-design.component.html',
  styleUrl: './add-product-design.component.css'
})
export class AddProductDesignComponent implements OnInit{
  productDesign: any;


  protected showErrorMessage: boolean;
  protected errorMessage: any;
  protected genericImageLight:any;
  protected genericImageDark:any;
  protected genericImageLightSrc: any;
  protected genericImageDarkSrc: any;
  protected categories: any;
  protected operation: string = 'ADD';

  constructor(private adminApiService: AdminApiService, private location: Location,private route: ActivatedRoute) {
    this.showErrorMessage = false;
    this.productDesign = {
      message:null,description:null,
      styles:[],tags:null
    }

  }

  ngOnInit(): void {

    this.genericImageLight = [];
    this.genericImageDark = [];
    this.genericImageLightSrc = [];
    this.genericImageDarkSrc = [];

    let mainTagInitials = this.route.snapshot.queryParams["mainTagInitials"];
    let idPD = this.route.snapshot.queryParams["idPD"];
    if(mainTagInitials) {
      this.operation = "EDIT";
      this.adminApiService.getProductDesign(mainTagInitials, idPD).subscribe(productDesign => {
        this.productDesign = productDesign;

        for (let style of this.productDesign.styles) {
          this.genericImageLightSrc.push(environment.siteBaseURL + "/img/product-designs/" + mainTagInitials.toLowerCase()  + idPD + "-" + style.imagesPrefixSlug.toLowerCase() +  "-light.png");
          this.genericImageDarkSrc.push(environment.siteBaseURL + "/img/product-designs/" + mainTagInitials.toLowerCase()  + idPD + "-" + style.imagesPrefixSlug.toLowerCase() +  "-dark.png");
        }


      })
    }
  }

  handleFileSelect(evt:any, i:number, type: string): void {
    if(type == 'light') {
      this.genericImageLight[i] = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.genericImageLight[i]);

      reader.onload = () => {
        this.genericImageLightSrc[i] = reader.result as string;
      };
    } else {
      this.genericImageDark[i] = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.genericImageDark[i]);

      reader.onload = () => {
        this.genericImageDarkSrc[i] = reader.result as string;
      };
    }

  }


  save(): void {
    this.showErrorMessage = false;

    if (!this.productDesign.description || !this.productDesign.tags || !this.productDesign.message || this.productDesign.styles.length == 0) {
      this.showErrorMessage = true;
      this.errorMessage = 'All the input fields are mandatory';
      return;
    }


    this.adminApiService.createUpdateProductDesign(this.productDesign, this.operation)
      .subscribe(dbProductDesign => {
        console.log(dbProductDesign);

        let i = 0;
        for(let genericImageLight  of this.genericImageLight) {
          if (this.genericImageLight[i]) {
            this.imagesToUpload++;
            this.adminApiService.uploadProductDesignImage(dbProductDesign, this.productDesign.styles[i], this.genericImageLight[i], 'light', this.onImageUploaded.bind(this));
          }
          i++;
        }
        i = 0;
        for(let genericImageDark  of this.genericImageDark) {
          if(this.genericImageDark[i]) {
            this.imagesToUpload++;
            this.adminApiService.uploadProductDesignImage(dbProductDesign, this.productDesign.styles[i], this.genericImageDark[i], 'dark', this.onImageUploaded.bind(this));
          }
          i++;
        }

        if(this.imagesToUpload == 0) {
          //no image needed to update.. there will be no trigger to refresh the page, call it manually
          this.imagesToUpload++;
          this.onImageUploaded();
        }

      });
  }

  private imagesToUpload : number = 0;
  private onImageUploaded() {
    this.imagesToUpload--;

    if(this.imagesToUpload == 0) {
      //all uploaded
      window.location.assign("/view-product-designs")
    }
  }


  addStyle() {
    this.productDesign.styles.push({name:'',imagesPrefixSlug:''});

  }

}
