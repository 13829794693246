import { Component } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AdminApiService} from "../service/admin-api.service";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-dictionaries',
  standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
      CommonModule
    ],
  templateUrl: './dictionaries.component.html',
  styleUrl: './dictionaries.component.css'
})
export class DictionariesComponent {
  dictionaryName: any;
  dictionaryItems: any;
  newItemValue: any;

  constructor(private adminApiService: AdminApiService) {

  }


  onDictionaryNameChange() {
    this.adminApiService.getDictionaryItems(this.dictionaryName).subscribe(
      (response) => {
        this.dictionaryItems = response;
      }
    );
  }

  addNewValue() {
    this.adminApiService.addDictionaryItem(this.dictionaryName, this.newItemValue).subscribe(
      (response) => {
        this.dictionaryItems.push(this.newItemValue);
        this.newItemValue = null;
      }
    );
  }

  deleteItem(value: any) {
    this.adminApiService.deleteDictionaryItem(this.dictionaryName, value).subscribe(
      (response) => {
        this.dictionaryItems.splice(this.dictionaryItems.indexOf(value), 1);
      }
    );
  }
}
