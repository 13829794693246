import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SessionStateService {

  constructor(private cookieService: CookieService, private http: HttpClient) { }

  notAuthenticated() {
    return this.cookieService.get("IdToken") === '';

  }

  signIn(email: any, password: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth' });
    const options = { headers: headers };

    const data =

    {
      "AuthParameters" : {
      "USERNAME" : email,
          "PASSWORD" : password
    },
      "AuthFlow" : "USER_PASSWORD_AUTH",
        "ClientId" : environment.cognitoClientPoolId
    }



    // add request to send email or into mysql
    return this.http.post<any>('https://cognito-idp.eu-central-1.amazonaws.com/login', data, options);



  }

  newPassword(email: any, newPassword: any, signInResponse: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.RespondToAuthChallenge' });
    const options = { headers: headers };

    const data =

        {
          "ChallengeName": "NEW_PASSWORD_REQUIRED",
          "ChallengeResponses": {

            "NEW_PASSWORD": newPassword,
            "USERNAME": email
          },
          "Session": signInResponse.Session,
          "ClientId" : environment.cognitoClientPoolId
        }



    // add request to send email or into mysql
    return this.http.post<any>('https://cognito-idp.eu-central-1.amazonaws.com/login', data, options);
  }

  storeSignInData(authenticationResult: any) {
    //example for response
    // {
    //   "AccessToken": "eyJraWQiOiJzKzN0a20wOTM4WU9oa0JVVE1QaTFMM3Z3VTY1YmYzTFpCYjljZnY2MEhvPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhNzU2Njc1ZS1hMjdkLTQzNzktYWU3OS1kMjZjMTY0ZjIyNzUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV95VDVxWEU1TEciLCJjbGllbnRfaWQiOiI0dWwxZHRvZ3E3c2xxa252aWVnYzVlcWE2YSIsIm9yaWdpbl9qdGkiOiI3ZTE4ZGIyNC04MGViLTQyZTktYmM0Ni0wYmNkYmZjYWQ0MDMiLCJldmVudF9pZCI6IjkzMmMxZjhjLTdjYTktNDRhOS1hNTAwLWJjOTNiMjQ2MmVjZiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MTAyNTEzOTQsImV4cCI6MTcxMDI1NDk5NCwiaWF0IjoxNzEwMjUxMzk0LCJqdGkiOiIwNzVmMTRlZC1kY2M2LTQxMWMtYmVmMS0zYTQwY2U3ZDhjMmIiLCJ1c2VybmFtZSI6ImE3NTY2NzVlLWEyN2QtNDM3OS1hZTc5LWQyNmMxNjRmMjI3NSJ9.SLfyQ35V_gBbBGwOIdIAD1FuuDluGJNL56iX1SmDZpS8HH4qu9SCZHUxwNMqXoIqP1Yebi9yeeKvAWxUIMv3DHdZZ_GDv4ugtf5n9h_C-HRUdrR9BDUsOYMMXT7Dc1h2ww71T9TEBWVQlP7GBClNgKyhiZE5bCqlcaPQguNWY5xG9GYghharIYECZz8O1LUbymLmeGXpZgmk9En9yiAfSKOgHmGGKZL-dJADnPBRHMaiOsmKvYpb8y2uP48GAk929xmWgU7655HsRAC_qTWhHlnzOojKstEXcmyljAtTTIBIdPe-TI5WQZQEPr2_RssijGCIeCH4c3GP76nCcivb9g",
    //     "ExpiresIn": 3600,
    //     "IdToken": "eyJraWQiOiJYQ2htc3B1bUdFMjd4dzFmQlo4c2N2aVRTVTlpTm0yN1BBTXlCdDBXM3FnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhNzU2Njc1ZS1hMjdkLTQzNzktYWU3OS1kMjZjMTY0ZjIyNzUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfeVQ1cVhFNUxHIiwiY29nbml0bzp1c2VybmFtZSI6ImE3NTY2NzVlLWEyN2QtNDM3OS1hZTc5LWQyNmMxNjRmMjI3NSIsIm9yaWdpbl9qdGkiOiI3ZTE4ZGIyNC04MGViLTQyZTktYmM0Ni0wYmNkYmZjYWQ0MDMiLCJhdWQiOiI0dWwxZHRvZ3E3c2xxa252aWVnYzVlcWE2YSIsImV2ZW50X2lkIjoiOTMyYzFmOGMtN2NhOS00NGE5LWE1MDAtYmM5M2IyNDYyZWNmIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTAyNTEzOTQsImV4cCI6MTcxMDI1NDk5NCwiaWF0IjoxNzEwMjUxMzk0LCJqdGkiOiJkODkyMDg3YS1hOGVlLTRiZWMtODFmYi1jMjk2NTFhNWFjMDIiLCJlbWFpbCI6Im1paGFpLm1pcm9zYW51QGdtYWlsLmNvbSJ9.bhXEKKOWr7P5SaVXwSsK1_Z5BJThQb0M0tOnPlwYTTocKUc5AXjZo1da8H0ODzxnYAWIErdp9nJ9qrCBLFYqDX2a9TqvKPfVCUgsJhUGp0RKv76uXQnsp4Ap1jEnxM6db9v6_Jv4eLof2dChafyjjQRyuOHfbDaHRRgnA5poiTJ58JzOttsCJ5wXPEufdeD-2ruSH53UNqheaocEP0eCjMXfhA17dPsabfYethCmTDAmaxbHIANAUS0cxos4UClfCCcI5JJItmrmYZt_dRBBiFT248PN5K28CZ_eLjxk_gvApxvSMpOMtGtl55Ltjkeb7IesVBns6mIG4TMnDplB9A",
    //     "RefreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.IsB341t4Ascp3vMh_4n29yYmMpJph3A0j-ySYUuqsf19ZytgTm_36hdLjZFy56AQNasifcg1cSxs2OIqCVkE3-kKeOsRJfa5S65mFSCKvbZc8IGEqVCX-QRmzHkguOu6--x_-52oLsm59VSlGFLMLwXOLMXZGXWVE8IN7UMbJIByIy6X3RonC0pLgNsYsxUAMAG3RD0rur9RlwKWyC7mjvXmJ4rbl7r_6VtLv5nQ1ZoCsD6rycFibbJLRSVBgWxLiy5Rke7Jq24N20yTD2-pK27Gv9Xeo_MMOwG22X53UuizEpe_dQ6_ONKVDBFr59TAawQf2-Dnp1ZLVmt_ZAo6gw.Zj3PT-y21skh8p-6.8nJBAfxfqWoYrtGXFMOUtGJY0OjsNhkKKNmIvPuxnlfNq94xWtrz6et5HTWyK-u3fNdYobKMSZ582MFRFQpkvLkfVxLAV_-wqo3d5NbHpU_EjArXCUz5Dz4K2c_cAcjwBmJyojd5-BKHffKAIXSnDaQHNi9BayS6KxuyWZwEnwBAUkRps_i4ZdZRp_HXGtIPOpVrrRlQIlTNH-KU_8p-lPqmu9lQ8D8gsAA95YJEN4qLsePZV-LrXd4PKJGKU-j-vuRfNDYoPizZjwiIBLilXjRKjeNWM4YmJUW2XJoj-zLfNNq6JBqtgkkx7W0XfePtSw8YwFHz-KkbQTy5yVOhjqs9mCKEhaef9cKfRsYAdWqQyRHtTSwtkOIv9T4uxiLFimxzC6nytGeoa-ONodsuPKe5-5ojtPM3fEwR69ekeRZjDjUkFxln33qpdGHz6ukKiEFaAVU2Q69GFFE-bRdhDIxhsOsvkA6Pv8PIWxcSvXNukIiRFKaYpEl1YnMV3M929LhikRtSLuFeHf1P3hCyVBW9pu4ZkOLu18UgraU6dhT_KN66Wwid0twP2DUnF4pGDYuUSoBeaGmgAAy_fpPvMauZg-6fh3evvSctTTqwgN7BK6ahFb-RGPrv-Im9EwiZY5bOopdUyBju9it8TysR41t6rgM8NeqWykA2B6hlLrJ3bRw9urld1KginImNWnR-NZQZoaX0pRml-modERMFRE4rexydGydNUwAErh3o_-jCBaEYkZM5_2xAnJfs5DLN9IOdx1IoQe51JbFDzKktSJtzjqXmyJQMOqPvQOqxHSD6ECE1dB9mWQ5e6VS6fEQJKYxk84kKTJpnZfVRc4DeyjEYkr9Yif4j_WaCbPKjOD6n64Cp0cZ-0c8CDV9BBRc2yFsbWG5ExYDhoW45u1fSmirOZx46GdVpDiiPwJ9a-oUmvHXzgy4iLBKGJzoeCzuv-Oe1SjO1fB4glC-XHfMAipDzUgESTLJmo4Rk7UJsTM_JBkmRoQHn8GE3dfdf7_7I7f9JdaQ5pnArd9F9Be3MGM4vQy2k1zjgSMQzjQyadWgI_n2CrYqWJ_6tO6bYab4FoKqynCWGVvZsC8tw-0xGcuKTD7u1HcCPawd8krz2y2OEthrnlHzxlnubg-q4P-rI4Ss8FBfCZpWG8piYkHG1mUTukE1DyTudtS-jQy6XoFAb_mI1YbsjT09LlVzEQhaolf7XgMc7xXlkXly7XXstu0bxGIaPcRan1VKYfkZGAkNDzHDFQ67eEs9_XvMSzFbLE7LNXFdr-Qd6_GzastJa--5aoZN1_TDSCkQEqfeZRAq7AFYX1bEXiYYSMfc7L8Eaffsb.TB06bGX_TyZvEeEQvZMGMQ",
    //     "TokenType": "Bearer"
    // }


    this.cookieService.set("AccessToken", authenticationResult.AccessToken);
    this.cookieService.set("RefreshToken", authenticationResult.RefreshToken);
    this.cookieService.set("IdToken", authenticationResult.IdToken);
  }

  getIdToken() {
    return this.cookieService.get("IdToken");
  }


  signOut() {
    this.cookieService.delete("AccessToken");
    this.cookieService.delete("RefreshToken");
    this.cookieService.delete("IdToken");
    window.location.assign("/");
  }

  refreshTokenAndReload() {
    let refreshToken = this.cookieService.get("RefreshToken");
    if(refreshToken != null && refreshToken !== "" && refreshToken !== "undefined") {

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth' });
      const options = { headers: headers };

      const data =
        {
          "AuthParameters" : {
            "REFRESH_TOKEN" : refreshToken
          },
          "AuthFlow" : "REFRESH_TOKEN_AUTH",
          "ClientId" : environment.cognitoClientPoolId
        }



      this.http.post<any>('https://cognito-idp.eu-central-1.amazonaws.com/login', data, options)
        .subscribe(response => {
          this.cookieService.set("AccessToken", response.AuthenticationResult.AccessToken);
          this.cookieService.set("IdToken", response.AuthenticationResult.IdToken);
          window.location.reload();
        })

    } else {
      this.signOut();
    }
  }
}
