import {Component, OnInit} from '@angular/core';
import {AdminApiService} from "../service/admin-api.service";
import {RouterModule} from "@angular/router";
import {LowerCasePipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-view-products',
  standalone: true,
  imports: [FormsModule,
    NgForOf,
    NgIf,
    RouterModule, LowerCasePipe],
  templateUrl: './view-products.component.html',
  styleUrl: './view-products.component.css'
})
export class ViewProductsComponent implements OnInit{
  category: any;
  categories: any;
  products: any;

  filterTag: any;

  constructor(private adminApiService: AdminApiService) {
  }
  ngOnInit(): void {
    this.adminApiService.getDictionaryItems("categories").subscribe(response =>
      this.categories = response);
  }

  onTagSubmit() {
    if(this.filterTag === undefined || this.filterTag === '') {
      return;
    }
    this.adminApiService.getProductsByTag(this.filterTag).subscribe(response =>
      this.products = response);
  }


  onCategoryChange() {
    this.adminApiService.getProductsByCategory(this.category).subscribe(response =>
      this.products = response);
  }

  deleteProduct(product: any) {
    if(confirm("Are you sure you want to delete product " + product.name)) {
      this.adminApiService.deleteProduct(product).subscribe(response=>{
        window.location.assign("/view-products");
      })

    }
  }

  protected readonly environment = environment;
}
