import {ErrorHandler, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {SessionStateService} from "./session-state.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private sessionStateService:SessionStateService) {
  }
    handleError(error: Error | HttpErrorResponse): void {
        // Log the error to the console.
        console.error(error);

      if (error instanceof HttpErrorResponse && error.status == 401) {
        this.sessionStateService.refreshTokenAndReload();
        return;
      }


        let message = 'An unexpected error occurred';
        if(error instanceof HttpErrorResponse && error.error && error.error.message) {
            message = error.error.message;
        }

        // Display a friendly error message to the user.
        alert( message);
    }
}
