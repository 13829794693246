import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { FormsModule} from "@angular/forms";
import {CommonModule, Location, NgOptimizedImage} from "@angular/common";
import {AdminApiService} from "../service/admin-api.service";
import {ActivatedRoute} from "@angular/router";
import Utils from "../service/utils";
import {environment} from "../../environments/environment";
@Component({
  selector: 'app-add-product',
  standalone: true,
  imports: [CommonModule, FormsModule, NgOptimizedImage],
  templateUrl: './add-product.component.html',
  styleUrl: './add-product.component.css'
})
export class AddProductComponent implements OnInit{
  product: any;
  showErrorMessage: boolean;
  errorMessage: any;
  categories: any;
  protected operation: string;
  protected rawProducts: any;
  protected rawProduct: any;
  protected productDesigns: any;
  protected productDesign: any;
  tag: any;

  protected image2Src: any;
  protected image2: any;
  protected image1: any;
  protected image1Src: any;
  protected mainImage: any;
  protected mainImageSrc: any;


  constructor(private adminApiService: AdminApiService, private location: Location,private route: ActivatedRoute) {
    this.showErrorMessage = false;
    this.product = {
      category:null,description:null,
      name:null,tags:null
    }
    this.operation = 'ADD';

  }

  ngOnInit(): void {
    this.image1 = [];
    this.image1Src = [];
    this.image2 = [];
    this.image2Src = [];

    this.adminApiService.getDictionaryItems("categories").subscribe(response =>
      this.categories = response);
    let sku = this.route.snapshot.queryParams["sku"];
    if(sku) {
      this.operation = "EDIT";
      this.adminApiService.getProduct(sku).subscribe(product => {
        this.product = product;

        this.mainImageSrc = environment.siteBaseURL + "/img/product/" + sku.toLowerCase() + ".png";

        this.rawProduct = this.product.rawProduct;
        this.productDesign = this.product.productDesign;

        let i = 0,j = 0;
        for(let color of this.rawProduct.colors) {
          this.image1[i] = [];
          this.image2[i] = [];
          this.image1Src[i] = [];
          this.image2Src[i] = [];
          j = 0;
          for(let style of this.productDesign.styles) {
            this.image1Src[i][j] = environment.siteBaseURL + "/img/product/" + sku.toLowerCase() + '-' + style.imagesPrefixSlug.toLowerCase() +
              "-" + color.slug.toLowerCase() + "-" + 1 + ".png";
            this.image2Src[i][j] = environment.siteBaseURL + "/img/product/" + sku.toLowerCase() + '-' + style.imagesPrefixSlug.toLowerCase() +
              "-" + color.slug.toLowerCase() + "-" + 2 + ".png";

            j++;
          }
          i++;
        }

      })
    }
  }



  save(): void {
    this.showErrorMessage = false;

    if (!this.productDesign || !this.rawProduct ) {
      this.showErrorMessage = true;
      this.errorMessage = 'You need to select a Raw Product and a Product Design';
      return;
    }

    if (!this.product.description || !this.product.name || !this.product.price || !this.product.tags || !this.product.category) {
      this.showErrorMessage = true;
      this.errorMessage = 'All the input fields are mandatory';
      return;
    }

    this.product.productDesign = this.productDesign;
    this.product.rawProduct = this.rawProduct;

    if(this.operation == 'ADD') {
      this.adminApiService.getProduct(this.product.rawProduct.shortSKU + this.product.rawProduct.idRP +
        this.product.productDesign.mainTagInitials + this.product.productDesign.idPD).subscribe((response: any) => {
        if (response && response.name) {
          this.showErrorMessage = true;
          this.errorMessage = 'There is already a product with the same sku defined: ' + response.name;
          return;
        }
        this.callCreateUpdateAPI();
      }, error => {
          //product not found, all good!
        this.callCreateUpdateAPI();
      })
    } else {
      this.callCreateUpdateAPI();
    }
  }

  private callCreateUpdateAPI() {
    this.adminApiService.createUpdateProduct(this.product, this.operation)
      .subscribe(dbProduct => {
        console.log(dbProduct);

        if (this.mainImage) {
          this.imagesToUpload++;
          this.adminApiService.uploadMainProductImage(dbProduct, this.mainImage, this.onImageUploaded.bind(this));
        }

        let i = 0, j = 0;

        for(let color of this.rawProduct.colors) {
          j = 0;
          for(let style of this.productDesign.styles) {
            if (this.image1[i] && this.image1[i][j]) {
              this.imagesToUpload++;
              this.adminApiService.uploadProductImage(dbProduct,style,color,1, this.image1[i][j], this.onImageUploaded.bind(this));
            }
            if (this.image2[i] && this.image2[i][j]) {
              this.imagesToUpload++;
              this.adminApiService.uploadProductImage(dbProduct,style,color,2, this.image2[i][j], this.onImageUploaded.bind(this));
            }
            j++;
          }
          i++;
        }

        if(this.imagesToUpload == 0) {
          //no need to wait for the callback, redirect!
          window.location.assign("/view-products")
        }

      });
  }

  private imagesToUpload : number = 0;
  private onImageUploaded() {
    this.imagesToUpload--;

    if(this.imagesToUpload == 0) {
      //all uplaoded
      window.location.assign("/view-products")
    }
  }


  fetchRawProducts() {
    this.adminApiService.getRawProductsByCategory(this.product.category).subscribe((response) => {
      this.rawProducts = response;
    });
  }

  fetchProductDesigns() {
    this.adminApiService.getProductDesignsByTag(this.tag).subscribe((response) => {
      this.productDesigns = response;
    });
  }

  generateNameDescriptionAndTags() {
    if (!this.productDesign || !this.rawProduct ) {
      alert('You need to select a Raw Product and a Product Design');
      return;
    }
    this.product.name = this.rawProduct.name + " - " + this.productDesign.message + " - " + this.toNameFromTags(this.productDesign.tags);
    this.product.tags = this.productDesign.tags;
  }

  private toNameFromTags(tags: any) {
    return tags.replaceAll(',',' / ').toUpperCase();
  }

  handleFileSelect(evt: any, i: number, j: number, firstImage: boolean) {
    if(!this.image1[i]) {this.image1[i] = [];}
    if(!this.image2[i]) {this.image2[i] = [];}
    if(!this.image1Src[i]) {this.image1Src[i] = [];}
    if(!this.image2Src[i]) {this.image2Src[i] = [];}

    if(firstImage) {

      this.image1[i][j] = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.image1[i][j]);

      reader.onload = () => {
        this.image1Src[i][j] = reader.result as string;
      };
    } else {
      this.image2[i][j] = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.image2[i][j]);

      reader.onload = () => {
        this.image2Src[i][j] = reader.result as string;
      };
    }



  }

  handleFileSelectForMainImage(evt: any) {
    this.mainImage = evt.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.mainImage);

    reader.onload = () => {
      this.mainImageSrc = reader.result as string;
    };
  }
}
