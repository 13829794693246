<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <h2 class="h3 py-2 text-center text-sm-start">Settings</h2>
  <!-- Tabs-->
  <ul class="nav nav-tabs nav-justified" role="tablist">
    <li class="nav-item"><a class="nav-link px-0 active" href="#profile" data-bs-toggle="tab" role="tab">
      <div class="d-none d-lg-block"><i class="ci-user opacity-60 me-2"></i>Profile</div>
      <div class="d-lg-none text-center"><i class="ci-user opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Profile</span></div></a></li>
    <li class="nav-item"><a class="nav-link px-0" href="#notifications" data-bs-toggle="tab" role="tab">
      <div class="d-none d-lg-block"><i class="ci-bell opacity-60 me-2"></i>Notifications</div>
      <div class="d-lg-none text-center"><i class="ci-bell opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Notifications</span></div></a></li>
    <li class="nav-item"><a class="nav-link px-0" href="#payment" data-bs-toggle="tab" role="tab">
      <div class="d-none d-lg-block"><i class="ci-card opacity-60 me-2"></i>Payment methods</div>
      <div class="d-lg-none text-center"><i class="ci-card opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Payment</span></div></a></li>
  </ul>
  <!-- Tab content-->
  <div class="tab-content">
    <!-- Profile-->
    <div class="tab-pane fade show active" id="profile" role="tabpanel">
      <div class="bg-secondary rounded-3 p-4 mb-4">
        <div class="d-flex align-items-center"><img class="rounded" src="img/marketplace/account/avatar.png" width="90" alt="Createx Studio">
          <div class="ps-3">
            <button class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Change <span class='d-none d-sm-inline'>avatar</span></button>
            <div class="p mb-0 fs-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
          </div>
        </div>
      </div>
      <div class="row gx-4 gy-3">
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-fn">First Name</label>
          <input class="form-control" type="text" id="dashboard-fn" value="John">
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-ln">Last Name</label>
          <input class="form-control" type="text" id="dashboard-ln" value="Doe">
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-email">Email address</label>
          <input class="form-control" type="text" id="dashboard-email" value="contact@example.com" disabled>
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-profile-name">Profile Name</label>
          <input class="form-control" type="text" id="dashboard-profile-name" value="Createx Studio">
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-country">Country</label>
          <select class="form-select" id="dashboard-country">
            <option value>Select country</option>
            <option value="Argentina">Argentina</option>
            <option value="Belgium">Belgium</option>
            <option value="France">France</option>
            <option value="Germany">Germany</option>
            <option value="Madagascar" selected>Madagascar</option>
            <option value="Spain">Spain</option>
            <option value="UK">United Kingdom</option>
            <option value="USA">USA</option>
          </select>
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-city">City</label>
          <input class="form-control" type="text" id="dashboard-city" value="Antananarivo">
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-address">Address Line</label>
          <input class="form-control" type="text" id="dashboard-address" value="Some Cool Street, 22/1">
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="dashboard-zip">ZIP Code</label>
          <input class="form-control" type="text" id="dashboard-zip">
        </div>
        <div class="col-12">
          <hr class="mt-2 mb-4">
          <div class="d-sm-flex justify-content-between align-items-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="freelancer" checked>
              <label class="form-check-label" for="freelancer">I'm available for freelance</label>
            </div>
            <button class="btn btn-primary mt-3 mt-sm-0" type="submit">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Notifications-->
    <div class="tab-pane fade" id="notifications" role="tabpanel">
      <div class="bg-secondary rounded-3 p-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="nf-disable-all" data-master-checkbox-for="#notifocation-settings">
          <label class="form-check-label fw-medium" for="nf-disable-all">Enable/disable all notifications</label>
        </div>
      </div>
      <div id="notifocation-settings">
        <div class="border-bottom p-4">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="nf-product-sold" checked>
            <label class="form-check-label" for="nf-product-sold">Product sold notifications</label>
          </div>
          <div class="form-text">Send an email when someone purchased one of my products</div>
        </div>
        <div class="border-bottom p-4">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="nf-product-updated" checked>
            <label class="form-check-label" for="nf-product-updated">Product update notifications</label>
          </div>
          <div class="form-text">Send an email when a product I've purchased is updated</div>
        </div>
        <div class="border-bottom p-4">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="nf-product-comment" checked>
            <label class="form-check-label" for="nf-product-comment">Product comment notifications</label>
          </div>
          <div class="form-text">Send an email when someone comments on one of my products</div>
        </div>
        <div class="border-bottom p-4">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="nf-product-review" checked>
            <label class="form-check-label" for="nf-product-review">Product review notification</label>
          </div>
          <div class="form-text">Send an email when someone leaves a review with their rating</div>
        </div>
        <div class="border-bottom p-4">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="nf-daily-summary">
            <label class="form-check-label" for="nf-daily-summary">Daily summary emails</label>
          </div>
          <div class="form-text">Send me a daily summary of all products sold, commented or reviewed</div>
        </div>
      </div>
      <div class="text-sm-end mt-4">
        <button class="btn btn-primary" type="submit">Save changes</button>
      </div>
    </div>
    <!-- Payment methods-->
    <div class="tab-pane fade" id="payment" role="tabpanel">
      <div class="bg-secondary rounded-3 p-4 mb-4">
        <p class="fs-sm text-muted mb-0">Primary payment method is used by default</p>
      </div>
      <div class="table-responsive fs-md mb-4">
        <table class="table table-hover mb-0">
          <thead>
          <tr>
            <th>Your credit / debit cards</th>
            <th>Name on card</th>
            <th>Expires on</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="py-3 align-middle">
              <div class="d-flex align-items-center"><img src="assets/img/card-visa.png" width="39" alt="Visa">
                <div class="ps-2"><span class="fw-medium text-heading me-1">Visa</span>ending in 4999<span class="align-middle badge badge-info ms-2">Primary</span></div>
              </div>
            </td>
            <td class="py-3 align-middle">John doe</td>
            <td class="py-3 align-middle">08 / 2019</td>
            <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
              <div class="ci-trash"></div></a></td>
          </tr>
          <tr>
            <td class="py-3 align-middle">
              <div class="d-flex align-items-center"><img src="assets/img/card-master.png" width="39" alt="MesterCard">
                <div class="ps-2"><span class="fw-medium text-heading me-1">MasterCard</span>ending in 0015</div>
              </div>
            </td>
            <td class="py-3 align-middle">John doe</td>
            <td class="py-3 align-middle">11 / 2021</td>
            <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
              <div class="ci-trash"></div></a></td>
          </tr>
          <tr>
            <td class="py-3 align-middle">
              <div class="d-flex align-items-center"><img src="assets/img/card-paypal.png" width="39" alt="PayPal">
                <div class="ps-2"><span class="fw-medium text-heading me-1">PayPal</span>j.doe&#64;example.com</div>
              </div>
            </td>
            <td class="py-3 align-middle">&mdash;</td>
            <td class="py-3 align-middle">&mdash;</td>
            <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
              <div class="ci-trash"></div></a></td>
          </tr>
          <tr>
            <td class="py-3 align-middle">
              <div class="d-flex align-items-center"><img src="assets/img/card-visa.png" width="39" alt="Visa">
                <div class="ps-2"><span class="fw-medium text-heading me-1">Visa</span>ending in 6073</div>
              </div>
            </td>
            <td class="py-3 align-middle">John doe</td>
            <td class="py-3 align-middle">09 / 2021</td>
            <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
              <div class="ci-trash"></div></a></td>
          </tr>
          <tr>
            <td class="py-3 align-middle">
              <div class="d-flex align-items-center"><img src="assets/img/card-visa.png" width="39" alt="Visa">
                <div class="ps-2"><span class="fw-medium text-heading me-1">Visa</span>ending in 9791</div>
              </div>
            </td>
            <td class="py-3 align-middle">John doe</td>
            <td class="py-3 align-middle">05 / 2021</td>
            <td class="py-3 align-middle"><a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit"><i class="ci-edit"></i></a><a class="nav-link-style text-danger" href="#" data-bs-toggle="tooltip" title="Remove">
              <div class="ci-trash"></div></a></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-sm-end"><a class="btn btn-primary" href="#add-payment" data-bs-toggle="modal">Add payment method</a></div>
    </div>
  </div>
</div>
