import { Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {ViewProductsComponent} from "./view-products/view-products.component";
import {AddProductComponent} from "./add-product/add-product.component";
import {DictionariesComponent} from "./dictionaries/dictionaries.component";
import {AddRawProductComponent} from "./add-raw-product/add-raw-product.component";
import {ViewRawProductsComponent} from "./view-raw-products/view-raw-products.component";
import {AddProductDesignComponent} from "./add-product-design/add-product-design.component";
import {ViewProductDesignsComponent} from "./view-product-designs/view-product-designs.component";
import {AddBannerComponent} from "./add-banner/add-banner.component";
import {ViewBannersComponent} from "./view-banners/view-banners.component";
import {environment} from "../environments/environment";


export const routes: Routes = [
  {path: '', component: HomeComponent, title: "Admin " + environment.name + " Dashboard"},
  {path: 'view-products', component: ViewProductsComponent, title: "Admin " + environment.name + ' View Products'},
  {path: 'add-product', component: AddProductComponent, title: "Admin " + environment.name + ' Add Product'},
  {path: 'add-raw-product', component: AddRawProductComponent, title: "Admin " + environment.name + ' Add Raw Product'},
  {path: 'view-raw-products', component: ViewRawProductsComponent, title: "Admin " + environment.name + ' View Raw Products'},
  {path: 'add-product-design', component: AddProductDesignComponent, title: "Admin " + environment.name + ' Add Product Design'},
  {path: 'view-product-designs', component: ViewProductDesignsComponent, title: "Admin " + environment.name + ' View Product Designs'},
  {path: 'dictionaries', component: DictionariesComponent, title: "Admin " + environment.name + ' Dictionaries'},
  {path: 'view-banners', component: ViewBannersComponent, title: "Admin " + environment.name + ' View Banners'},
  {path: 'add-banner', component: AddBannerComponent, title: "Admin " + environment.name + ' Add Banner'},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];
