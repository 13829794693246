import { Component } from '@angular/core';
import {LowerCasePipe, NgForOf, NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {AdminApiService} from "../service/admin-api.service";

import {environment} from "../../environments/environment";

@Component({
  selector: 'app-view-banners',
  standalone: true,
  imports: [
    LowerCasePipe,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './view-banners.component.html',
  styleUrl: './view-banners.component.css'
})
export class ViewBannersComponent {
  banners: any;

  constructor(private adminApiService: AdminApiService) {
  }
  ngOnInit(): void {
    this.adminApiService.getBanners().subscribe((response) => {
      this.banners = response;
    })
  }


  deleteBanner(banner: any) {
    if(confirm("Are you sure you want to delete banner " + banner.name)) {
      this.adminApiService.deleteBanner(banner).subscribe(response=>{
        window.location.assign("/view-banners");
      })

    }
  }

  protected readonly environment = environment;
}
