import {Component, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Location, NgForOf, NgIf} from "@angular/common";
import {AdminApiService} from "../service/admin-api.service";
import {routes} from "../app.routes";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-add-raw-product',
  standalone: true,
    imports: [
        FormsModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule
    ],
  templateUrl: './add-raw-product.component.html',
  styleUrl: './add-raw-product.component.css'
})
export class AddRawProductComponent implements OnInit{
  rawProduct: any;


  protected showErrorMessage: boolean;
  protected errorMessage: any;
  protected genericImage:any;
  protected genericImageSrc: string = '';
  protected categories: any;
  protected operation: string = 'ADD';

  protected image1:any;
  protected image2:any;
  protected image1Src: any;
  protected image2Src: any;

  constructor(private adminApiService: AdminApiService, private location: Location,private route: ActivatedRoute) {
    this.showErrorMessage = false;
    this.rawProduct = {
      category:null,description:null,
      colors:[],name:null,designBoundaries1:{x:null,y:null,width:null,height:null},designBoundaries2:{x:null,y:null,width:null,height:null}
    }

  }

  ngOnInit(): void {
    this.image1 = [];
    this.image1Src = [];
    this.image2 = [];
    this.image2Src = [];

    this.adminApiService.getDictionaryItems("categories").subscribe(response =>
      this.categories = response);
    let shortSKU = this.route.snapshot.queryParams["shortSKU"];
    let idRP = this.route.snapshot.queryParams["idRP"];
    if(shortSKU) {
      this.operation = "EDIT";
      this.adminApiService.getRawProduct(shortSKU, idRP).subscribe(rawProduct => {
        this.rawProduct = rawProduct;


        if(!this.rawProduct.designBoundaries1) {
          this.rawProduct.designBoundaries1 = {x: null, y: null, width: null, height: null};
          this.rawProduct.designBoundaries2 = {x: null, y: null, width: null, height: null};
        }

        this.genericImageSrc = environment.siteBaseURL + "/img/raw-product/" + shortSKU.toLowerCase()  + idRP + ".png";

        for (let color of this.rawProduct.colors) {
          this.image1Src.push(environment.siteBaseURL + "/img/raw-product/" + shortSKU.toLowerCase() + idRP + "-" + color.slug + "-1.png");
          this.image2Src.push(environment.siteBaseURL + "/img/raw-product/" + shortSKU.toLowerCase() + idRP + "-" + color.slug + "-2.png");
        }


      })
    }
  }




  handleGenericFileSelect(evt:any): void {
    this.genericImage = evt.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.genericImage);

    reader.onload = () => {
      this.genericImageSrc = reader.result as string;
    };
  }

  handleFileSelect(evt:any, i:number, number: string): void {
    if(number == '1') {
      this.image1[i] = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.image1[i]);

      reader.onload = () => {
        this.image1Src[i] = reader.result as string;
      };
    } else {
      this.image2[i] = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.image2[i]);

      reader.onload = () => {
        this.image2Src[i] = reader.result as string;
      };
    }

  }


  save(): void {
    this.showErrorMessage = false;

    if (!this.rawProduct.description || !this.rawProduct.category || !this.rawProduct.name || this.rawProduct.colors.length == 0) {
      this.showErrorMessage = true;
      this.errorMessage = 'All the input fields are mandatory';
      return;
    }

    if (!this.rawProduct.designBoundaries1.x || !this.rawProduct.designBoundaries1.y || !this.rawProduct.designBoundaries1.width || !this.rawProduct.designBoundaries1 ||
      !this.rawProduct.designBoundaries2.x || !this.rawProduct.designBoundaries2.y || !this.rawProduct.designBoundaries2.width || !this.rawProduct.designBoundaries2) {
      this.showErrorMessage = true;
      this.errorMessage = 'Design boundaries limits are mandatory';
      return;
    }

    for(let color of this.rawProduct.colors) {
      if (!color.name || !color.type) {
        this.showErrorMessage = true;
        this.errorMessage = 'All the input fields are mandatory';
        return;
      }
    }


    this.adminApiService.createUpdateRawProduct(this.rawProduct, this.operation)
      .subscribe(dbRawProduct => {
        this.rawProduct = dbRawProduct;

        let i = 0;
        for(let image1  of this.image1) {
          if (this.image1[i]) {
            this.imagesToUpload++;
            this.adminApiService.uploadRawProductImage(this.rawProduct, this.rawProduct.colors[i], this.image1[i], '1', this.onImageUploaded.bind(this));
          }
          i++;
        }
        i = 0;
        for(let image2  of this.image2) {
          if(this.image2[i]) {
            this.imagesToUpload++;
            this.adminApiService.uploadRawProductImage(this.rawProduct, this.rawProduct.colors[i], this.image2[i], '2', this.onImageUploaded.bind(this));
          }
          i++;
        }

        if(this.imagesToUpload == 0) {
          //no image needed to update.. there will be no trigger to refresh the page, call it manually
          this.onGenericImageSavedSuccessful();
        }


      });
  }

  private onGenericImageSavedSuccessful() {
    //all uplaoded
    window.location.assign("/view-raw-products")
  }

  private imagesToUpload : number = 0;
  private onImageUploaded() {
    this.imagesToUpload--;

    if(this.imagesToUpload == 0) {
      if(this.genericImage) {
        this.adminApiService.uploadRawProductGenericImage(this.rawProduct, this.genericImage, this.onGenericImageSavedSuccessful);
      } else {
        this.onGenericImageSavedSuccessful();
      }


    }
  }

  addColor() {
    this.rawProduct.colors.push({name:'',color:''});
  }

}
