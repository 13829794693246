<!-- Content-->
<div class="pt-2 px-4 ps-lg-0 pe-xl-5">
  <!-- Title-->
  <div class="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
    <h2 class="h3 py-2 me-2 text-center text-sm-start">{{operation == 'ADD'?'Add New':'Edit'}}  Product</h2>

  </div>
  <form>
    <div class="mb-3 pb-2 row">
      <div class="py-2 col-4">
        <label for="unp-category" class="form-label">Choose category</label>
        <select name="category" class="form-select me-2" id="unp-category" [(ngModel)]="product.category" (change)="fetchRawProducts()" required>
          <option *ngFor="let category of categories" value="{{category}}">{{category}}</option>
        </select>
      </div>
      <div class="py-2 col-8">
        <label class="form-label " for="unp-raw-product" >Choose Raw Product</label>
        <select name="category" class="form-select me-2" id="unp-raw-product" [(ngModel)]="rawProduct" required>
          <option *ngFor="let rawProduct of rawProducts" [ngValue]="rawProduct">{{rawProduct.name}}</option>
        </select>
      </div>

    </div>

    <div class="mb-3 pb-2 row">
      <div class="py-2 col-4">
        <label class="form-label " for="unp-raw-product" >Choose Tag</label>
        <div class="input-group">
          <input class="form-control" type="text" placeholder="Enter tag to filter by" id="unp-tag" name="filterTag" [(ngModel)]="tag">
          <button class="btn btn-primary" type="button" (click)="fetchProductDesigns()"><i class="ci-search fs-lg me-2"></i></button>
        </div>
      </div>
      <div class="py-2 col-8">
        <label class="form-label " for="unp-product-design" >Choose Product Design</label>
        <select name="product-design" class="form-select me-2" id="unp-product-design" [(ngModel)]="productDesign" required>
          <option *ngFor="let productDesign of productDesigns" [ngValue]="productDesign">{{productDesign.message}}</option>
        </select>
      </div>

    </div>
    <div class="mb-3 pb-2 row">
      <button class="btn btn-primary" type="button" (click)="generateNameDescriptionAndTags()"><i class="ci-settings fs-lg me-2"></i>Generate name, description and tags</button>
    </div>


    <div class="mb-3 pb-2">
      <label class="form-label" for="unp-product-name" >Product name</label>
      <input class="form-control" type="text" id="unp-product-name" name="name" [(ngModel)]="product.name">
      <div class="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
    </div>
    <div class="mb-3 py-2">
      <label class="form-label" for="unp-product-description">Product description</label>
      <textarea class="form-control" rows="6" id="unp-product-description" name="description" [(ngModel)]="product.description"></textarea>
      <div class="bg-secondary p-3 fs-ms rounded-bottom"><span class="d-inline-block fw-medium me-2 my-1">Markdown supported:</span><em class="d-inline-block border-end pe-2 me-2 my-1">*Italic*</em><strong class="d-inline-block border-end pe-2 me-2 my-1">**Bold**</strong><span class="d-inline-block border-end pe-2 me-2 my-1">- List item</span><span class="d-inline-block border-end pe-2 me-2 my-1">##Heading##</span><span class="d-inline-block">--- Horizontal rule</span></div>
    </div>

    <div class="row">
      <div class="col-sm-6 mb-3">
        <label class="form-label" for="unp-standard-price">Standard license price</label>
        <div class="input-group"><span class="input-group-text"><i class="ci-dollar"></i></span>
          <input class="form-control" type="text" id="unp-standard-price" name="price" [(ngModel)]="product.price">
        </div>
        <!--
        <div class="form-text">Average marketplace price for this category is $15.</div>
        -->
      </div>

    </div>
    <div class="mb-3 py-2">
      <label class="form-label" for="unp-product-tags">Product tags</label>
      <input class="form-control" rows="4" id="unp-product-tags" name="tags" [(ngModel)]="product.tags"/>
      <div class="form-text">Up to 10 keywords that describe your item. Tags should all be in lowercase and separated by commas.</div>
    </div>

    <div class="mb-3 pb-2" *ngIf="operation == 'EDIT'">
      <label class="col-form-label" >Main Image</label>
      <div class="file-drop-area mb-3" *ngIf="mainImage || operation == 'EDIT'">
        <img [src]="mainImageSrc" width="200" height="200"/>

      </div>
      <div class="file-drop-area mb-3" *ngIf="mainImage == null">
        <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
        <input class="file-drop-input" type="file"  (change)="handleFileSelectForMainImage($event)" accept="image/png">
        <div class="form-text">Main Product Image</div>
      </div>
    </div>


    <div class="row align-items-center" *ngIf="rawProduct && productDesign && operation == 'EDIT'">
      <label class="col-form-label" >Pictures</label>
      <div class="row" *ngFor="let color of rawProduct.colors; index as i">
        <div class="row" *ngFor="let style of productDesign.styles; index as j">
        <div class="col-12 pb-2">
          Picture for color {{color.name}} ({{color.type}}) and style {{style.name}}:
        </div>
        <div class="col-6 pb-2">
          <div class="mb-3 pb-2">
            <div class="file-drop-area mb-3" *ngIf="(image1[i] && image1[i][j]) || operation == 'EDIT'">
              <img [src]="image1Src[i][j]" width="200" height="200"/>

            </div>
            <div class="file-drop-area mb-3" *ngIf="image1[i] == null || image1[i][j] == null">
              <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
              <input class="file-drop-input" type="file"  (change)="handleFileSelect($event, i, j, true)" accept="image/png">
              <div class="form-text">Product Image 1</div>
            </div>
          </div>
        </div>
        <div class="col-6 pb-2">
          <div class="mb-3 pb-2">
            <div class="file-drop-area mb-3" *ngIf="(image2[i] && image2[i][j]) || operation == 'EDIT'">
              <img [src]="image2Src[i][j]" width="200" height="200"/>

            </div>
            <div class="file-drop-area mb-3" *ngIf="image2[i] == null || image2[i][j] == null">
              <div class="file-drop-icon ci-cloud-upload"></div><span class="file-drop-message">Drag and drop here to upload product screenshot</span>
              <input class="file-drop-input" type="file"  (change)="handleFileSelect($event, i, j, false)" accept="image/png">
              <div class="form-text">Product Image 2</div>
            </div>
          </div>
        </div>

      </div>
      </div>
    </div>



    <div class="alert alert-danger" *ngIf="showErrorMessage">
      <strong>Eroare!</strong> {{errorMessage}}.
    </div>


    <button class="btn btn-primary d-block w-100" type="submit" (click)="save()"><i class="ci-cloud-upload fs-lg me-2"></i>Upload Product</button>

  </form>
</div>
