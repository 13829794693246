import { Component } from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {SessionStateService} from "../../service/session-state.service";

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {

  constructor(private sessionStateService:SessionStateService) {
  }
  signOut() {
    this.sessionStateService.signOut();
  }
}
