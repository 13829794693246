import {Component, OnInit} from '@angular/core';
import {SignInModalComponent} from "../sign-in-modal/sign-in-modal.component";
import {SessionStateService} from "../../service/session-state.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    SignInModalComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  constructor(private sessionStateService: SessionStateService, private modalService: NgbModal) {
  }

  ngOnInit() {
    if(this.sessionStateService.notAuthenticated()) {
      this.modalService.open(SignInModalComponent);
    }
  }

  signOut() {
    this.sessionStateService.signOut();
  }
}
