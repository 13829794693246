import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {AdminApiService} from "../service/admin-api.service";
import {LowerCasePipe, NgForOf} from "@angular/common";
import {RouterModule} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-view-raw-products',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    RouterModule,
    LowerCasePipe
  ],
  templateUrl: './view-raw-products.component.html',
  styleUrl: './view-raw-products.component.css'
})
export class ViewRawProductsComponent implements OnInit {
  category: any;
  categories: any;
  rawProducts: any;

  constructor(private adminApiService: AdminApiService) {
  }
  ngOnInit(): void {
      this.adminApiService.getDictionaryItems("categories").subscribe(response =>
      this.categories = response);
  }



  onCategoryChange() {
    this.adminApiService.getRawProductsByCategory(this.category).subscribe(response =>
    this.rawProducts = response);
  }

  deleteRawProduct(rawProduct: any) {
    if(confirm("Are you sure you want to delete product " + rawProduct.name)) {
      this.adminApiService.deleteRawProduct(rawProduct).subscribe(response=>{
        window.location.assign("/view-raw-products");
      })

    }
  }

  protected readonly environment = environment;
}
